import "./App.css";
import globalStyle from "./styles/global.style.js";
import "animate.css/animate.min.css";

import { Layout } from "./styles/theme.config.js";
import Navigation from "./components/Navigation/Navigation";
import Hero from "./components/Hero/Hero";
import Products from "./components/Products/Product";
import About from "./components/About/About";
import Description from "./components/Description/Description";
import Artisans from "./components/Artisans/Artisans";
import ImageGallery from "./components/ImageGallery/ImageGallery";
import Sustainability from "./components/Sustainability/Sustainability";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";

import data from "./static/data.js";
import { Descriptions } from "antd";
import HeroBackground from "./components/HeroBackground/HeroBackground";

const App = () => {
  globalStyle();
  return (
    <Layout>
      <Navigation {...data} />

      <HeroBackground {...data} />

      <Hero {...data} />

      <Products {...data} />

      <About {...data} />

      <Description {...data} />

      <Artisans {...data} />

      {/* <Sustainability {...data} /> */}
      <ImageGallery id="certifications" {...data} />
      <Contact {...data} />

      <Footer {...data} />
    </Layout>
  );
};

export default App;
