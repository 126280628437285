import Logo from "./assets/logo.png";
import Background from "./assets/Hero_Background.png";
import Hero_Foreground from "./assets/Hero_Foreground.png";
import Product_1 from "./assets/Product_1.png";
import Product_2 from "./assets/Product_2.png";
import Product_3 from "./assets/Product_3.png";
import Product_4 from "./assets/Product_4.png";
import Product_5 from "./assets/Product_5.png";

import About_1_Background from "./assets/About_1_Background.png";
import About_1_Foreground from "./assets/About_1_Foreground.png";
import Description_1_Background from "./assets/Description_1_Background.png";
import Description_1_Foreground from "./assets/Description_1_Foreground.png";
import Sustainability_1_Background from "./assets/Sustainability_1_Background.png";
import Sustainability_1_Foreground from "./assets/Sustainability_1_Foreground.png";
import Artisan_Foreground from "./assets/Artisans_foreground.png";
import Artisan_Background from "./assets/Artisans_background.png";

import Product_6 from "./assets/Product_6.png";
import Product_7 from "./assets/Product_7.png";
import Product_8 from "./assets/Product_8.png";
import Product_9 from "./assets/Product_9.png";
import Product_10 from "./assets/Product_10.png";

import Certification_1 from "./assets/Certifications_1.png";
import Certification_2 from "./assets/Certifications_2.png";
import Certification_3 from "./assets/Certifications_3.png";
import Certification_4 from "./assets/Certifications_4.png";
import Certification_5 from "./assets/Certifications_5.png";
import Certification_6 from "./assets/Certifications_6.png";

import About_1 from "./assets/About_1.png";
// import About_2 from "./assets/About_2.png";
// import About_3 from "./assets/About_3.png";

import artisans from "./assets/artisans.png";
import sustainability from "./assets/sustainability.png";

const data = {
  companyName: "DELIGHT ART EMPORIUM",
  mainText: "Quality craftsmanship with a modern touch",
  subText:
    "Explore a wide range of exclusive handicrafts and home decor products. Get premium quality handcrafted woodworks to breathe freshness into your living spaces.",
  productSectionTitle: "Our Products",
  brandsTitle: "Brands we work with",
  certificationsTitle: "Compliances and Certifications",
  buttonContent: "Send an enquiry",
  logo: Logo,
  background: Background,
  foreground: Hero_Foreground,
  about: {
    heading: "About us",
    mainText:
      "At Delight Art Emporium, we handcraft products with love while celebrating local artisans and the Indian essence. Currently based in Rajasthan, we endeavour to cultivate Indian culture and heritage by bringing products of impeccable and uncompromised quality to our customers. Our dedication to maximum customer satisfaction encourages our professional in-house teams to create products tailor-fit for each client’s needs.",
    aboutBack: About_1_Background,
    aboutFore: About_1_Foreground,
  },
  description: {
    heading: "Mission",
    mainText: `We believe in creating products that attain optimum standards of quality with a hint of historical essence. We empower our artisans, designers, and workers with knowledge of our rich heritage, ensuring the best working environment in the light of their health and well-being. We aim to delight our customers with premium heirloom-quality products at the best competitive prices. 
    `,
    descBack: Description_1_Background,
    descFore: Description_1_Foreground,
  },
  artisans: {
    heading: "Infrastructure",
    mainText:
      "Delight Art Emporium takes pride and delight in our own in-house chemical treatment, seasoning chamber, manufacturing, finishing, fumigation, polishing, quality checking & packing facilities. We host a multi-tier control system backed by experienced personnel to quality check products at every step of the way.  Our products are handcrafted in Jodhpur, Rajasthan, with state-of-the-art production facilities to guarantee sustainable and authentic products.",
    foregroundImage: Artisan_Foreground,
    backgroundImage: Artisan_Background,
  },
  sustainability: {
    heading: "Sustainability",
    mainText:
      "We are dedicated to producing and delivering high-quality items to our consumers. Our goal is to satisfy the needs of our customers on time and to strive for continual development. Our products are one-of-a-kind because of the long hours of effort, combined with a humble desire to deliver the best to our customers all around the world",
    sustainabilityFore: Sustainability_1_Foreground,
    sustainabilityBack: Sustainability_1_Background,
  },

  prodLinkArray: [
    {
      title: "Rugs",
      link: "/#",
    },
    {
      title: "Pillows",
      link: "",
    },
    {
      title: "Poufs",
      link: "",
    },
    {
      title: "Wall hangers",
      link: "/#",
    },
  ],

  brandArray: [
    {
      title: "Company 1",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 2",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 3",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 4",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 5",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 6",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 7",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 8",
      link: "/#",
      image: Background,
    },
  ],
  prodArray: [
    {
      title: "Accent Table",
      image: Product_1,
      link: ""
    },
    {
      title: "Bench",
      image: Product_2,
      link: "",
    },
    {
      title: "Cabinet",
      image: Product_3,
      link: "",
    },
    {
      title: "Chair",
      image: Product_4,
      link: "",
    },
    {
      title: "Kitchen Trolley",
      image: Product_5,
      link: "",
    },
    {
      title: "Shelf Stand",
      image: Product_6,
      link: "",
    },
    {
      title: "TV Unit",
      image: Product_7,
      link: "",
    },
    {
      title: "Rack",
      image: Product_8,
      link: "",
    },
    {
      title: "Dresser",
      image: Product_9,
      link: "",
    },
    // {
    //   title: "Cyprus",
    //   image: Product_10,
    //   link: "",
    // },
  ],
  imgArray: [
    {
      image: Certification_1,
    },
    // {
    //   image: Certification_2,
    // },
    // {
    //   image: Certification_3,
    // },
    // {
    //   image: Certification_4,
    // },
    // {
    //   image: Certification_5,
    // },
    // {
    //   image: Certification_6,
    // },
  ],

  aboutData: [
    {
      image: About_1,
    },
    // {
    //   image: About_2,
    // },
    // {
    //   image: About_3,
    // },
  ],

  sustainabilityData: {
    title: "Sustainability is the way of life, the way of our manufacturing",
    text: "",
    image: sustainability,
  },

  artisansData: {
    title:
      "The artisans are the masters behind creating our world class products",
    text: "",
    image: artisans,
  },

  emails: ['info@delightartemp.com'],
};

export default data;
