import { styled } from "./../../styles/theme.config.js";

export const Container = styled("div", {
  variants: {
    type: {
      normal: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "row",
        width: "auto",
        // maxHeight: "753px",
        // minHeight: "80vh",
        zIndex: -1,
        objectFit: "cover",
        // margin: "0 auto",
        // marginTop: "7em",
        padding: "10em 0 0 0",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        "@media only screen and (max-width : 1000px)": {
          padding: "2em",
          marginTop: "-2em",
          flexDirection: "column",
          display: "none",
        },
      },
      weird: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "row",
        width: "95vw",
        maxHeight: "1000px",
        minHeight: "90vh",
        zIndex: -1,
        margin: "0 auto",
        marginTop: "7em",
        padding: "2em 2em",
        objectFit: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        "@media only screen and (max-width : 1000px)": {
          padding: "2em",
          marginTop: "-2em",
          flexDirection: "column",
          display: "none",
        },
      },
    },
  },
});

export const Box = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  background: "darkgrey",
  minHeight: "80vh",

  "@media only screen and (max-width : 1000px)": {
    flexDirection: "column",
    marginTop: "3em",
  },
});
export const Content = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "top",
  alignItems: "flex-start",
  padding: "5em 1em 0 5em",
  width: "70%",
  "@media only screen and (max-width : 1000px)": {
    width: "90%",
    height: "initial",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "1em",
  },
});

export const Para = styled("p", {

  fontSize: "$3",
  lineHeight: "2em",
  width: "80%",
  "@media only screen and (max-width : 1000px)": {
    textAlign: "center",
    width: "100%",
    fontSize: "$2",
  },
  color: "rgba(33, 36, 39, 1)",

  fontFamily: "$secondary",
});

export const Header = styled("h1", {
  variants: {
    type: {
      normal: {
        fontSize: "42px",
        lineHeight: "60px",
        width: "80%",
        marginBottom: "0.5em",
        color: "#000000",
        fontFamily: "$font",
      },
      wierd: {
        fontSize: "42px",
        lineHeight: "60px",
        width: "80%",
        marginBottom: "0.5em",
        color: "#FFFFFF",
      },
    },
  },
});

export const Image = styled("img", {
  padding: "0 3em",
  width: "50%",
  "@media only screen and (max-width : 1000px)": {
    width: "80vw",
  },
});

export const Button = styled("a", {
  lineHeight: "32px",
  fontFamily: "$secondary",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "rgba(29, 38, 36, 1)",
  fontSize: "$9",
  fontWeight: "700",
  height: "3em",
  padding: "0.5em 1.5em",
  margin: "3em 0",
  borderRadius: "40px",
  border: "none",
  color: "$white",
  textDecoration: "none",
  "&:hover": {
    color: "$white",
  },
});
