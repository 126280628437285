import { styled } from "./../../styles/theme.config.js";

export const Container = styled("div", {
  padding: "5em 10em",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  "@media only screen and (max-width : 600px)": {
    padding: "3em",
  },
});

export const Header = styled("h2", {
  fontFamily: "$secondary",
  textAlign: "center",
  margin: "0.5em",
});

export const Image = styled("img", {
  width: "150px",
  display: "inline",
  margin: "auto",
  padding: "0em",
  borderRadius: "3px",
  "@media only screen and (max-width : 600px)": {
    width: "30vw",
  },
});

export const Content = styled("div", {
  border: "none !important",
  margin: "2em 0",
  padding: 0,
});

export const Box = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
  justifyContent: "center",
  margin: "0 auto",
});

export const Product = styled("img", {
  width: "100px",
  margin: "1em",
});

export const Link = styled("a", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  textDecoration: "none",
});
