import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Animation from "react-animate-on-scroll";

// <S.Container id="about">
//     {data.map((value, key) => (
//         key % 2 == 0
//         ?
//         <S.Box>
//             <S.Content>
//                 <S.Header>
//                     {value.title}
//                 </S.Header>
//                 <S.Text>
//                     {value.text}
//                 </S.Text>
//             </S.Content>
//             <S.Content>
//                 <S.Image src={value.image}/>
//             </S.Content>
//         </S.Box>
//         :
//         <S.Box>
//             <S.Content>
//                 <S.Image src={value.image}/>
//             </S.Content>
//             <S.Content>
//                 <S.Header>
//                     {value.title}
//                 </S.Header>
//                 <S.Text>
//                     {value.text}
//                 </S.Text>
//             </S.Content>
//         </S.Box>
//     ))}
// </S.Container>

//     )
// }

import * as S from "./About.style.js";
import globalStyle from "../../styles/global.style";

const About = (data) => {
  globalStyle();

  const settings = {
    
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
  };

  return (
    <Animation animateIn="animate__fadeInUp" animateOnce={true}>
      <S.Container
        style={{
          backgroundImage: 'url("' + data.about.aboutBack + '")',
        }}
        id="about"
      >
        <S.Content className="hideMedium">
          <S.Image src={data.about.aboutFore} />
        </S.Content>
        <S.Box className="hideMedium">
          <S.Header>{data.about.heading}</S.Header>
          <S.Text>{data.about.mainText}</S.Text>
        </S.Box>

        <S.Box className="showMedium">
          <S.Header>{data.about.heading}</S.Header>
          <S.Text>{data.about.mainText}</S.Text>
        </S.Box>
        <S.Content className="showMedium">
          <S.Image src={data.about.aboutFore} />
        </S.Content>
      </S.Container>
    </Animation>
  );
};

export default About;
