import * as S from "./Products.style.js";

import Animation from "react-animate-on-scroll";

const Products = (props) => {
  const { productSectionTitle, prodArray, buttonContent } = props;
  console.log({ productSectionTitle, prodArray, buttonContent });
  return (
    <S.Container id="products">
      <S.Header>{productSectionTitle}</S.Header>
      <S.Content>
        {prodArray.map((product, key) => (
          <Animation
            animateIn="animate__fadeInUp"
            animateOnce={true}
            delay={100 + key * 100}
          >
            <S.Product href={product.link} target="_blank">
              <S.Image src={product.image} />
              <S.Title>{product.title}</S.Title>
              <S.Link href={product.link} target="_blank">
                View products
              </S.Link>
            </S.Product>
          </Animation>
        ))}
      </S.Content>
      <Animation animateIn="animate__fadeInUp" animateOnce={true}>
        <S.Button href="/#contact">{buttonContent}</S.Button>
      </Animation>
    </S.Container>
  );
};

export default Products;
