import { styled } from "./../../styles/theme.config.js";

export const Container = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "row",
  width: "auto",
  // zIndex: -,
  margin: "2em 0",
  "@media only screen and (max-width : 750px)": {
    textAlign: "justify",
    padding: "1em 0em",
    flexDirection: "column",
  },
});

export const Box = styled("div", {
  display: "flex",
  margin: "15em 2em",
  width: "50%",
  flexDirection: "column",
  "@media only screen and (max-width : 750px)": {
    padding: "2em 2em 0 2em",
    margin: "8em 2em 2em 2em",
    width: "100%",
    alignItems: "flex-start",
    margin: "2em 0",
  },
});

export const Header = styled("h1", {
  paddingTop: "1em",
  color: "$bodyColor",
  fontFamily: "$secondary",
  "@media only screen and (max-width : 750px)": {
    marginTop: "2em",
    fontSize: "$8",
  },
});

export const Text = styled("p", {
  padding: "2em 0 0 0",
  color: "$bodyColor",
  lineHeight: "32px",
  fontFamily: "$secondary",
  "@media only screen and (max-width : 750px)": {
    textAlign: "justify",
    padding: "1em 0em",
  },
});

export const Image = styled("img", {
  padding: "2em 2em",
  width: "85%",
  "@media only screen and (max-width : 750px)": {
    width: "100%",
    padding: "1em",
  },
});

export const Content = styled("div", {
  display: "flex",
  flexDirection: "row",
  padding: "5em 0 0 0",
  width: "50%",
  margin: "0 auto",
  "@media only screen and (max-width : 750px)": {
    flexDirection: "column",
    width: "100% !important",
    padding: "2em 1em",
  },
});

export const Video = styled("video", {});
